import React, { CSSProperties, useEffect, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'hooks';

import { selectAllChangesCount } from '../../reducers/unsaved';

import ModalCard from 'tsx/components/ModalCard';
import ChangesDialog from '../ChangesDialog';
import PublishDialog from '../PublishDialog';
import { getSettings } from 'tsx/features/main/actions/login';
import { selectCompanySettings } from 'tsx/features/main/reducers/login';

interface ComponentProps {
  modalProps: any;
}

const SaveLoad: React.FC<ComponentProps> = ({ modalProps }) => {
  const [isChangesOpen, setIsChangesOpen] = useState(false);
  const [IsPublishOpen, setIsPublishOpen] = useState(false);
  const changesCount = useAppSelector(selectAllChangesCount);

  const dispatch = useAppDispatch();

  const settings = useAppSelector(selectCompanySettings);

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  const currrentPublishDate = settings.published_date as string;

  const style: CSSProperties = { flex: 'none', width: '48%' };
  const header = `Save / Load ${changesCount !== 0 ? `(${changesCount} changes)` : ''}`;

  return (
    <ModalCard {...modalProps} header={header}>
      <div className="save-load-container">
        <div className="row">
          <label>Unsaved Changes:</label>
          <span>{changesCount}</span>
        </div>
        <ChangesDialog
          isOpen={isChangesOpen}
          onClose={() => {
            setIsChangesOpen(false);
          }}
        />
        <div className="row underline">
          <Button
            style={style}
            size="sm"
            disabled={changesCount === 0}
            color="success"
            onClick={() => setIsChangesOpen(true)}
          >
            Save
          </Button>
          <span>Save all pending appointment changes</span>
        </div>

        <PublishDialog
          isOpen={IsPublishOpen}
          onClose={() => setIsPublishOpen(false)}
          originalDate={currrentPublishDate}
        />
        <div className="row">
          <label>Current Published Date:</label>
          <Input
            type="date"
            id="currentPublishDate"
            name="currentPublishDate"
            defaultValue={currrentPublishDate}
            disabled
          />
        </div>
        <div className="row underline">
          <Button className="btn publish-btn btn-sm" style={style} onClick={() => setIsPublishOpen(true)}>
            Publish
          </Button>
          <span>Publish Appointments to New Date</span>
        </div>

        <div className="row">
          <label>Import Allocations From:</label>
        </div>
        <div className="row">
          <label>Week starting:</label>
          <span>
            <select disabled={true}>
              <option>Select</option>
            </select>
          </span>
        </div>
        <div className="row">
          <Button className="btn import-btn btn-sm" style={style} disabled={true}>
            Import
          </Button>
          <span>Allocations will only apply to unallocated appts</span>
        </div>
      </div>
    </ModalCard>
  );
};

export default SaveLoad;
