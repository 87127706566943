import React, { CSSProperties, Fragment } from 'react';
import { useAppSelector } from 'hooks';
import { useDroppable } from '@dnd-kit/core';
import { Dayjs } from 'dayjs';
import classNames from 'classnames';

import {
  Worker,
  calcIndexFromTime,
  Event,
  Travel,
  Appointment,
  Conflict,
  Break,
  getGridColumn,
  WorkerAvailability,
} from '../../lib/common';

import AppointmentCard from '../AppointmentCard';
import TravelCard from '../TravelCard';
import BreakCard from '../BreakCard';

import { selectUnallocated } from '../../reducers/weeklyPlannerAppointments';
import { selectFocusedAppointment, selectOptions } from '../../reducers/weeklyPlanner';
import { MAX_PIPS } from './DailyView';

interface ComponentProps {
  date: Dayjs;
  worker: Worker;
  events: Event[];
  style?: CSSProperties;
}

const DailyContent: React.FC<ComponentProps> = ({ date, worker, events, style }) => {
  const unallocated = useAppSelector(selectUnallocated);
  const { showBreaks, showTravel } = useAppSelector(selectOptions);

  const focused = useAppSelector(selectFocusedAppointment);
  const { appointment: focusedAppointment } = focused || {};
  const { id: focusId } = focusedAppointment || {};

  const { id } = worker;
  const { setNodeRef, isOver, active, rect } = useDroppable({ id });

  const current = active?.data?.current ?? null;
  const containerWidth = rect?.current?.width ?? null;
  const isDraggingOrigin = current?.containerId === id;
  const key = `timeline-${id}`;

  const renderAppointments = (cards: Appointment[], index: string) => {
    // If more than one appointment in a row, there are conflicts
    // Set row as a grid and show appointments side-by-side
    return (
      <Fragment key={index}>
        {cards.map((appointment: any, index: number) => {
          const style: CSSProperties = {
            gridRow: index + 1,
            ...getGridColumn(appointment.start_time, appointment.end_time, 1),
          };

          return (
            <AppointmentCard
              key={`${id}-${index}`}
              appointment={appointment}
              style={style}
              containerId={id}
              containerWidth={containerWidth ?? 1}
              maxSegments={MAX_PIPS}
              displayOnTransform={isOver && isDraggingOrigin}
              events={events}
            />
          );
        })}
      </Fragment>
    );
  };

  const renderAvailability = (date: Dayjs) => {
    const dayOfWeek = date.format('dddd');
    if (!worker.availability) return null;

    const availability = worker?.availability?.[dayOfWeek as keyof WorkerAvailability] ?? {};
    const { start, end } = availability;

    if (!start || !end) return null;

    // handle edge cases for 00:00 / 24:00 start & end
    let startIndex = start === '0000' || start === '000' || start === '2400' ? 1 : calcIndexFromTime(start);
    let endIndex = end === '0000' || end === '000' || end === '2400' ? MAX_PIPS : calcIndexFromTime(end);

    // same start & end time (full availability)
    if (start === end) {
      startIndex = 1;
      endIndex = MAX_PIPS;
    }

    const style: CSSProperties = { gridColumn: `${startIndex + 1} / span ${endIndex - startIndex}`, gridRow: 1 };
    return <div className="availability" style={style}></div>;
  };

  const renderTravels = (cards: Travel[], index: string) => {
    return (
      <Fragment key={index}>
        {cards
          .map((travel, index: number) => {
            // Don't render travel cards with no defined start/end times.
            const {
              from: { time: start },
              to: { time: end },
            } = travel;
            if (!start || !end) return false;

            const style: CSSProperties = {
              gridRow: index + 1,
              ...getGridColumn(start, end, 1),
            };

            return <TravelCard key={index} travel={travel} style={style} />;
          })
          .filter(Boolean)}
      </Fragment>
    );
  };

  const renderBreaks = (cards: Break[], index: string) => {
    return (
      <Fragment key={index}>
        {cards
          .map((breakData, index: number) => {
            // Don't render travel cards with no defined start/end times.
            const {
              from: { time: start },
              to: { time: end },
            } = breakData;
            if (!start || !end) return false;

            const style: CSSProperties = {
              gridRow: index + 1,
              ...getGridColumn(start, end, 1),
            };

            return <BreakCard key={index} data={breakData} style={style} />;
          })
          .filter(Boolean)}
      </Fragment>
    );
  };

  const renderConflict = (data: Conflict[], index: number) => {
    // Group all appointments together and render
    // Group all travels together and render a range travel
    const appointments: any[] = [];
    const travels: any[] = [];
    const breaks: any[] = [];
    data.forEach((events) =>
      events.forEach(({ type, data }: Event) => {
        switch (type) {
          case 'appointment': {
            appointments.push(data);
            break;
          }
          case 'travel': {
            travels.push(data);
            break;
          }
          case 'break': {
            breaks.push(data);
            break;
          }
          default: {
            break;
          }
        }
      }),
    );

    return (
      <Fragment key={index}>
        {renderAppointments(appointments, `appointment-${index}`)}
        {showTravel && renderTravels(travels, `travel-${index}`)}
        {showBreaks && renderBreaks(breaks, `break-${index}`)}
      </Fragment>
    );
  };

  const renderEvents = (events: Event[]) => {
    return events
      .map(({ type, data }, index) => {
        switch (type) {
          case 'appointment': {
            return renderAppointments([data], `${type}-${index}`);
          }
          case 'conflict': {
            return renderConflict(data, index);
          }
          case 'travel': {
            return showTravel && renderTravels([data], `${type}-${index}`);
          }
          case 'break': {
            return showBreaks && renderBreaks([data], `${type}-${index}`);
          }
          default: {
            return false;
          }
        }
      })
      .filter(Boolean);
  };

  const renderDragPlaceholders = () => {
    if (current === null) return <></>;

    const appointment = current.appointment;
    const style = current.containerStyle;
    return (
      <>
        {renderDragPlaceholder(style)}
        {isOver && !isDraggingOrigin && (
          <AppointmentCard
            key={`${key}-dragging`}
            className="dragging"
            appointment={appointment}
            style={{ ...style, gridRow: 'auto' }}
            events={events}
          />
        )}
      </>
    );
  };

  const renderDragPlaceholder = (style: CSSProperties) => (
    <div key={`${key}-placeholder`} className="drag-placeholder" style={{ ...style, gridRow: '1 / -1' }} />
  );

  const renderFocusedPlaceholder = () => {
    const appointment = unallocated.find(({ id }) => focusId && id === focusId);
    if (!appointment) return <></>;

    const style = getGridColumn(appointment.start_time, appointment.end_time, 1);
    return renderDragPlaceholder(style);
  };

  const className = classNames('worker-timeline', {
    'is-dragging-over': true,
    'is-dragging-origin': isDraggingOrigin,
  });
  return (
    <div id={key} key={key} className={className} ref={setNodeRef} style={style}>
      {renderAvailability(date)}
      {renderEvents(events ?? [])}
      {current !== null && renderDragPlaceholders()}
      {focusId !== null && renderFocusedPlaceholder()}
    </div>
  );
};

export default DailyContent;
