import React, { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import dayjs, { Dayjs } from 'dayjs';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import classNames from 'classnames';
import { throttle } from 'lodash';

import Spinner from 'tsx/components/Spinner';
import WorkerRow from './WorkerRow';

import { buildWeeklyChange } from '../lib/simulate';
import { simulate } from '../actions/unsaved';

import { selectLoading, selectParams, updateWeeklyPlannerParams } from '../reducers/weeklyPlanner';
import { selectSimulatedChanges } from '../reducers/unsaved';

interface ComponentProps {
  date: Dayjs;
  onDateChange?: (value: Dayjs) => void;
  onViewChange?: (toggle: boolean) => void;
}

const WeeklyView: React.FC<ComponentProps> = ({ date, onDateChange, onViewChange }) => {
  const dispatch = useAppDispatch();
  const startDate = date.weekday(0);
  const daysOfWeek = Array.from({ length: 7 }, (_, i) => startDate.add(i, 'day'));
  const weeklyPlannerData = useAppSelector(selectSimulatedChanges);
  const weeklyPlannerDataLoading = useAppSelector(selectLoading);
  const { limit } = useAppSelector(selectParams);
  const isLoading = weeklyPlannerDataLoading === 'pending';
  const containerRef = useRef<HTMLDivElement>(null);

  const setView = (value: Dayjs, isDailyView: boolean) => {
    onDateChange && onDateChange(value);
    onViewChange && onViewChange(isDailyView);
  };

  const onScroll = useCallback(
    throttle(async () => {
      if (!containerRef.current || isLoading) return;

      const { scrollHeight, scrollTop, clientHeight } = containerRef.current;
      const nearBottom = scrollHeight - (scrollTop + clientHeight) < 50;

      if (!isLoading && nearBottom && limit <= weeklyPlannerData.length) {
        dispatch(updateWeeklyPlannerParams({ limit: limit + 30 }));
      }
    }, 200),
    [limit, weeklyPlannerData.length, dispatch, isLoading],
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener('scroll', onScroll, { passive: true });
    return () => container.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  const onDragEnd = (event: any) => {
    const { active, over } = event;
    if (!over) return;

    const { containerId: oldCell, appointment } = active.data.current;
    const newCell = over.id;
    if (oldCell === newCell) return; //appt dropped in same worker cell

    const change = buildWeeklyChange(weeklyPlannerData, oldCell, newCell, startDate, appointment);
    dispatch(simulate({ ...change, week_start: startDate }));
  };

  const headerColumnData = daysOfWeek.map((day) => (
    <div key={day.toString()} className={`header-cell ${day.isSame(dayjs(), 'day') ? 'today' : ''}`}>
      <span className="clickable" onClick={() => setView(day, true)}>
        {day.format('ddd (DD/MMM)')}
      </span>
    </div>
  ));

  const containerClassNames = classNames('weekly-view-container', {
    loading: isLoading,
    'initial-load': weeklyPlannerData.length < 1,
  });

  return (
    <div>
      <div ref={containerRef} className={`${containerClassNames}`}>
        <div className="header-cell">Care Worker</div>
        {headerColumnData}
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={onDragEnd}
          modifiers={[restrictToFirstScrollableAncestor]}
        >
          {weeklyPlannerData?.map((worker, index) => (
            <WorkerRow
              key={`${worker.id}-${index}`}
              worker={worker}
              days={daysOfWeek}
              date={startDate}
              style={{
                gridRow: index + 2,
              }}
            />
          ))}
        </DndContext>
        {weeklyPlannerData.length >= 1 && <Spinner loading={isLoading} className="p-5" />}
      </div>
      {weeklyPlannerData.length < 1 && <Spinner loading={isLoading} className="p-5" />}
    </div>
  );
};

export default WeeklyView;
