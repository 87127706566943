import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'hooks';
import dayjs, { Dayjs } from 'dayjs';

import ContainerPanel from 'tsx/components/ContainerPanel';

import Banner from './components/Banner';
import { SideBar } from './components/SideBar';
import WeeklyView from './components/WeeklyView';
import DailyView from './components/DailyView/DailyView';

import { getAll } from './actions/weeklyPlanner';
import { getAll as getCareQualificationLevels } from 'tsx/features/appointments/actions/careQualificationLevels';
import { getAll as getServiceTypes } from 'tsx/features/appointments/actions/serviceTypes';

import { toggleQuickMenu } from 'tsx/features/main/reducers/app';
import {
  updateWeeklyPlannerParams,
  openAppointmentDetails,
  selectFocusedAppointment,
  focusAppointment,
  selectLoading,
  selectParams,
  selectFocusedWorkerId,
  selectIsFocusedWorkerInRows,
} from './reducers/weeklyPlanner';
import { setTitle } from '../main/actions/login';
import { formatDateString } from 'tsx/libs/dayjs';
import { deriveClientLink } from '../appointments/lib/derive';
import Link from 'tsx/components/Link';
import { Icon, icons } from 'tsx/components/Icon';
import { getAll as getUsers } from 'tsx/features/main/actions/users';

dayjs.updateLocale('en', {
  weekStart: 1,
});

const WeeklyPlanner: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useAppSelector(selectParams);
  const weeklyPlannerDataLoading = useAppSelector(selectLoading);

  const focused = useAppSelector(selectFocusedAppointment);
  const focusWorkerId = useAppSelector(selectFocusedWorkerId);
  const isWorkerInRows = useAppSelector(selectIsFocusedWorkerInRows);
  const { appointment: focusedAppointment } = focused || {};
  const { id: focusAppointmentId, date: focusDate = null } = focusedAppointment || {};

  const isLoading = weeklyPlannerDataLoading === 'pending';

  const [date, setDate] = useState<Dayjs>(dayjs().weekday(0));
  const [dayView, setDayView] = useState(false);

  const [isRightClickMenuOpen, setRightClickMenuOpen] = useState(false);
  const [clickedId, setClickedId] = useState<number | null>();
  const [clickedClientId, setClickedClientId] = useState<number | null>();
  const [clickedParentRepeatId, setClickedParentRepeatId] = useState<number | null>();

  const [clickPoint, setClickPoint] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    setTitle('Weekly Planner');
    // Set quick menu narrow by default for extra screen space
    dispatch(toggleQuickMenu(true));

    dispatch(
      getCareQualificationLevels({
        attributes: ['id', 'name', 'colour'],
      }),
    );
    dispatch(getServiceTypes());
    dispatch(getUsers({ sort: ['surname'] }));

    document.addEventListener('click', leftClickCheck);
    document.addEventListener('contextmenu', contextClick);
    return () => {
      document.removeEventListener('click', leftClickCheck);
      document.removeEventListener('contextmenu', contextClick);
    };
  }, []);

  useEffect(() => {
    load();
  }, [params]);

  useEffect(() => {
    const week_start = !dayView ? date.weekday(0) : date;
    const params = {
      week_start: week_start.toISOString(),
      week_end: dayView ? week_start.add(1, 'day').toISOString() : null,
      limit: focusAppointmentId ? 0 : 30,
      ...(focusAppointmentId != null && { focus_appointment_id: focusAppointmentId }),
    };
    dispatch(updateWeeklyPlannerParams(params));

    setTitle(`${formatDateString(week_start)} - Weekly Planner `);
  }, [dayView, date]);

  useEffect(() => {
    if (focusAppointmentId) {
      if (!dayView) setDayView(true);
      setDate(dayjs(focusDate));
    }
  }, [focusAppointmentId]);

  useEffect(() => {
    if (focusWorkerId !== null && !isWorkerInRows) {
      dispatch(updateWeeklyPlannerParams({ ...params, focus_worker_id: focusWorkerId }));
    }
  }, [focusWorkerId]);

  const load = (searchParams?: { [key: string]: any }) => {
    if (params.week_start && !isLoading) {
      dispatch(getAll({ ...params, ...searchParams }));
      setClickedId(null);
      dispatch(openAppointmentDetails(clickedId));
    }
  };

  //On right click check
  const contextClick = (event: MouseEvent) => {
    const clickTarget = event.target as HTMLElement;
    const appointmentCard = clickTarget.closest('.appointment-card');
    setClickedId(null);
    dispatch(openAppointmentDetails(null));

    if (appointmentCard) {
      event.preventDefault();
      if (appointmentCard) {
        setClickedId(Number(appointmentCard.getAttribute('data-id')));
        setClickedClientId(Number(appointmentCard.getAttribute('data-client-id')));
        setClickedParentRepeatId(Number(appointmentCard.getAttribute('data-parent-repeat-id')));
      }
      setClickPoint({ x: event.clientX, y: event.clientY });
      setRightClickMenuOpen(true);
    }
  };

  //On left click check
  const leftClickCheck = (event: MouseEvent) => {
    const dropdown = document.getElementById('weekly-planner-context') as HTMLElement;
    if (!dropdown?.contains(event.target as Node)) {
      setClickedId(null);
      dispatch(openAppointmentDetails(null));
      setRightClickMenuOpen(false);
    }
  };

  const setDateChange = (value: Dayjs) => {
    dispatch(focusAppointment(null));
    const dateValue = dayView ? value : value.weekday(0);
    !date.isSame(dateValue, 'day') && setDate(dateValue);
  };
  const setViewChange = (toggle: boolean) => {
    setDayView(toggle);
    dispatch(focusAppointment(null));
  };

  const { x: left, y: top } = clickPoint;
  const dropdownStyle: CSSProperties = { top, left, position: 'absolute' };

  const MenuLink = ({ to, children, disabled = false }: { to: string; children: ReactNode; disabled?: boolean }) => {
    return (
      <Link to={to} target="_blank" className="text-decoration-none" disabled={disabled}>
        <DropdownItem disabled={disabled}>
          {children}
          <Icon icon={icons.faUpRightFromSquare} className="hover-icon" />
        </DropdownItem>
      </Link>
    );
  };

  return (
    <div className="p-2 weekly-planner">
      <Banner isDayView={dayView} onDateChange={setDateChange} onViewChange={setViewChange} onRefresh={load} />
      <ContainerPanel className="canvas gx-0 flex-nowrap" sidepanel={<SideBar date={date} />}>
        {dayView ? (
          <DailyView date={date} onDateChange={setDateChange} />
        ) : (
          <WeeklyView date={date} onDateChange={setDateChange} onViewChange={setViewChange} />
        )}
      </ContainerPanel>
      <Dropdown
        id="weekly-planner-context"
        style={dropdownStyle}
        isOpen={isRightClickMenuOpen}
        toggle={() => setRightClickMenuOpen(!isRightClickMenuOpen)}
        className="weekly-planner-context"
      >
        <DropdownMenu>
          <DropdownItem
            onClick={(event) => {
              event.stopPropagation();
              dispatch(openAppointmentDetails(clickedId));
            }}
          >
            View Details
          </DropdownItem>
          <DropdownItem divider></DropdownItem>
          <MenuLink to={`${window.origin}/appointments/${clickedId}`}>View Appointment</MenuLink>
          <MenuLink to={`${window.origin}/appointments/${clickedParentRepeatId}`} disabled={!clickedParentRepeatId}>
            View Parent
          </MenuLink>
          <MenuLink to={deriveClientLink({ client_id: clickedClientId })}>View Client</MenuLink>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default WeeklyPlanner;
