import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { LoadingState, Row } from 'tsx/types/reducers';
import {
  typePrefix,
  getAll,
  getAwardAlerts,
  getConflicts,
  getRejected,
  getUnallocated,
  getMissingQualifications,
} from '../actions/weeklyPlannerAppointments';

import { Appointment } from '../lib/common';
import { selectAllChanges as selectUnsavedChanges } from './unsaved';
import { addCommonReducers } from 'tsx/libs/reduxUtils';

interface weeklyPlannerAppointmentsState {
  loading: LoadingState;
  error: string | null | undefined;
  rows: Array<any>;
  rejected: Row[];
  conflicts: Row[];
  missingQualifications: Row[];
  awardAlerts: Row[];
  unallocated: Appointment[];

  filterOptions?: {
    client_id: number;
    user_id: number;
    suburb: string;
    date: string;
  };
}

const initialState: weeklyPlannerAppointmentsState = {
  loading: 'idle',
  error: null,
  rows: [],
  rejected: [],
  conflicts: [],
  missingQualifications: [],
  awardAlerts: [],
  unallocated: [],
};

export const weeklyPlannerAppointments = createSlice({
  name: typePrefix,
  initialState,
  reducers: {
    setFilterOptions(state, action) {
      state.filterOptions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConflicts.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.conflicts = action.payload.data;
    });
    builder.addCase(getRejected.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rejected = action.payload.data;
    });
    builder.addCase(getAwardAlerts.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.awardAlerts = action.payload.data;
    });
    builder.addCase(getUnallocated.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.unallocated = action.payload.data;
    });
    builder.addCase(getMissingQualifications.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.missingQualifications = action.payload.data;
    });

    addCommonReducers<weeklyPlannerAppointmentsState>(builder, typePrefix, getAll);
  },
});
export const { setFilterOptions } = weeklyPlannerAppointments.actions;
export const selectAll = (state: RootState) => state.weeklyPlannerAppointments.rows;
export const selectAwardAlerts = (state: RootState) => state.weeklyPlannerAppointments.awardAlerts;
export const selectRejected = (state: RootState) => state.weeklyPlannerAppointments.rejected;
export const selectConflicts = (state: RootState) => state.weeklyPlannerAppointments.conflicts;
const selectAllUnallocated = (state: RootState) => state.weeklyPlannerAppointments.unallocated;
export const selectMissingQualifications = (state: RootState) => state.weeklyPlannerAppointments.missingQualifications;

// Look for unsaved changes matching the ids of unallocated appointments, do not show if "allocated"
export const selectUnallocated = createSelector(
  [selectAllUnallocated, selectUnsavedChanges],
  (unallocated, unsavedAppointments) => unallocated.filter(({ id }) => !(id in unsavedAppointments)),
);

export default weeklyPlannerAppointments.reducer;
