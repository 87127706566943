import React from 'react';
import { Dayjs } from 'dayjs';
import { dateInputFieldFormat } from 'tsx/libs/dayjs';
import SaveLoad from './SaveLoad';
import Conflicts from './Conflicts';
import Rejected from './Rejected';
import AwardAlerts from './AwardAlerts';
import MissingQualifications from './MissingQualifications';
import UnallocatedAppointments from './UnallocatedAppointments';

interface ComponentProps {
  date: Dayjs;
}

const SideBar: React.FC<ComponentProps> = ({ date }) => {
  const weekStart = date.weekday(0).format(dateInputFieldFormat);
  const modalProps = {
    className: 'rounded-0 mt-1',
    headerClassName: 'rounded-0',
    bodyClassName: 'p-0',
  };

  return (
    <div className="side-bar-container">
      <SaveLoad modalProps={modalProps} />
      <Conflicts weekStart={weekStart} modalProps={modalProps} />
      <Rejected weekStart={weekStart} modalProps={modalProps} />
      <AwardAlerts weekStart={weekStart} modalProps={modalProps} />
      <MissingQualifications weekStart={weekStart} modalProps={modalProps} />
      {/* This will need to be moved into Unallocated appointments as an action bar toggle */}
      {/* <ModalCard {...modalProps} header="Search">
        <Search />
      </ModalCard> */}
      <UnallocatedAppointments weekStart={weekStart} modalProps={modalProps} />
    </div>
  );
};

export default SideBar;
