import { createThunks } from 'tsx/libs/reduxUtils';
import { get } from 'tsx/libs/genericAction';
import {
  apiWeeklyPlannerAppointments,
  apiWeeklyPlannerAppointmentConflicts,
  apiWeeklyPlannerAppointmentUnallocated,
  apiWeeklyPlanner,
  apiWeeklyPlannerAppointmentMissingQualifications,
} from 'tsx/constants/api';
import { AppointmentStatuses } from 'tsx/constants/maps';

export const typePrefix = 'weekly-planner-appointments';

const thunkConfig = [
  { actionType: 'getAll', apiPath: apiWeeklyPlannerAppointments, method: get, actionParams: { limit: 0 } },
  { actionType: 'getAwardAlerts', apiPath: `${apiWeeklyPlanner}/users/award-alerts`, method: get },
  {
    actionType: 'getRejected',
    apiPath: apiWeeklyPlannerAppointments,
    method: get,
    actionParams: { status_id: AppointmentStatuses.CARER_REJECTED },
  },
  { actionType: 'getMissingQualifications', apiPath: apiWeeklyPlannerAppointmentMissingQualifications, method: get },
  { actionType: 'getConflicts', apiPath: apiWeeklyPlannerAppointmentConflicts, method: get },
  { actionType: 'getUnallocated', apiPath: apiWeeklyPlannerAppointmentUnallocated, method: get },
];

export const { getAll, getAwardAlerts, getRejected, getConflicts, getUnallocated, getMissingQualifications } =
  createThunks(typePrefix, thunkConfig);
