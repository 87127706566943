import { SearchProps } from 'tsx/components/FormFields/Search';
import { selectLookupUsers } from 'tsx/features/main/reducers/users';

const fields: SearchProps = {
  user_id: {
    defaultShow: true,
    type: 'multi-lookup',
    field: 'id',
    caption: 'Care Worker',
    placeholder: 'All',
    selectorOptions: selectLookupUsers,
  },
};

export default fields;
