// Set Gateway API parameters from Environment
const API_HOST = process.env.REACT_APP_API_HOST ?? `${window.location.protocol}//${window.location.hostname}`;
const API_PORT = process.env.REACT_APP_API_PORT;

// Set Gateway API URL from Environment
const url = `${API_HOST}${API_PORT ? `:${API_PORT}` : ''}`;

// API constants
const apiPrefix = `${url}/v2`;

/**
 * Appointments
 */
export const apiAppointments = `${apiPrefix}/appointments`;
export const apiAppointmentActivities = `${apiAppointments}/activities`;
export const apiAppointmentCancellationReasons = `${apiAppointments}/cancellation-reasons`;
export const apiAppointmentRepeatFrequencies = `${apiAppointments}/repeat-frequencies`;
export const apiAppointmentServiceTypes = `${apiAppointments}/service-types`;
export const apiAppointmentStatuses = `${apiAppointments}/statuses`;

/**
 * Clients
 */
export const apiClients = `${apiPrefix}/clients`;
export const apiClientPackages = `${apiClients}/packages`;
export const apiClientReferrers = `${apiClients}/referrers`;
export const apiClientPackageSchedules = `${apiClients}/packages/schedules`;

/**
 * Users
 */
export const apiUsers = `${apiPrefix}/users`;
export const apiAllowances = `${apiUsers}/allowances`;
export const apiUserDepartments = `${apiUsers}/departments`;
export const apiUserLevels = `${apiUsers}/levels`;
export const apiUserPayGroups = `${apiUsers}/pay-groups`;
export const apiUserPayLevels = `${apiUsers}/pay-levels`;

/**
 * Weekly Planner
 */
export const apiWeeklyPlanner = `${apiPrefix}/weekly-planner`;
export const apiWeeklyPlannerAppointments = `${apiWeeklyPlanner}/appointments`;
export const apiWeeklyPlannerAppointmentConflicts = `${apiWeeklyPlanner}/appointments/conflicts`;
export const apiWeeklyPlannerAppointmentMissingQualifications = `${apiWeeklyPlanner}/appointments/missing-qualifications`;
export const apiWeeklyPlannerAppointmentsPublish = `${apiWeeklyPlanner}/publish`;
export const apiWeeklyPlannerAppointmentsPublishEstimates = `${apiWeeklyPlanner}/publish/estimates`;
export const apiWeeklyPlannerAppointmentUnallocated = `${apiWeeklyPlanner}/appointments/unallocated`;

// Unaliased / root level endpoints
export const apiAddressLookup = `${apiPrefix}/address-lookup`;
export const apiAuthenticateSession = `${apiPrefix}/authenticate-session`;
export const apiCareQualificationLevels = `${apiPrefix}/care-qualification-levels`;
export const apiEquipments = `${apiPrefix}/equipments`;
export const apiLeaveCodes = `${apiPrefix}/leave-codes`;
export const apiLeaveRequests = `${apiPrefix}/leave-requests`;
export const apiLocations = `${apiPrefix}/locations`;
export const apiLogin = `${apiPrefix}/login`;
export const apiMedications = `${apiPrefix}/medications`;
export const apiNDISSupportItems = `${apiPrefix}/ndis/support-items`;
export const apiPaymentTypes = `${apiPrefix}/payment-types`;
export const apiSettings = `${apiPrefix}/settings`;
export const apiStates = `${apiPrefix}/states`;
export const apiTaskClassifications = `${apiPrefix}/tasks/classifications`;
export const apiTaskTypes = `${apiPrefix}/tasks/types`;
export const apiCompanies = `${apiPrefix}/companies`;
