import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon, icons } from 'tsx/components/Icon';
import ModalCard from 'tsx/components/ModalCard';

import { buildAssignChange } from '../../lib/simulate';
import { Appointment } from '../../lib/common';

import UnallocatedCard from '../UnallocatedCard';
import UnallocatedViewMenu from './UnallocatedViewMenu';
import Search from './Search';

import { getUnallocated } from '../../actions/weeklyPlannerAppointments';
import { simulate } from '../../actions/unsaved';

import { selectUnallocated } from '../../reducers/weeklyPlannerAppointments';
import { selectSimulatedChanges } from '../../reducers/unsaved';

interface Filters {
  client_id: number | null;
  user_id: number | string | null;
  suburb: string | null;
  date: string | null;
}

interface ComponentProps {
  weekStart: string;
  modalProps: any;
}

const UnallocatedAppointments: React.FC<ComponentProps> = ({ weekStart, modalProps }) => {
  const dispatch = useAppDispatch();
  const appointments = useAppSelector(selectUnallocated);
  const weeklyPlannerData = useAppSelector(selectSimulatedChanges);

  const [openAppointmentId, setOpenAppointmentId] = useState<string | null>(null);
  const [isSearchOpen, setSearchOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filters>({
    client_id: null,
    user_id: null,
    suburb: null,
    date: null,
  });

  useEffect(() => {
    dispatch(getUnallocated({ week_start: weekStart }));
  }, [weekStart]);

  const onAssign = (appointment: Appointment) => {
    // Build assign is expecting multiple appointments being assigned in one run, add an array of one change.
    const change = buildAssignChange(weeklyPlannerData, [appointment]);
    dispatch(simulate({ ...change, week_start: weekStart }));
  };

  const onBulkAssign = () => {
    const targets = appointments.filter(({ repeat, user_id, assignable_users }) => {
      if (!repeat || !user_id) return false;

      const user = assignable_users.find(({ id }) => id === Number(user_id));
      if (!user) return false;

      const { is_qualified, is_available } = user;
      if (!is_qualified || !is_available) return false;
      return true;
    });

    const change = buildAssignChange(weeklyPlannerData, targets);
    dispatch(simulate({ ...change, week_start: weekStart }));
  };

  const onMenuToggle = (appointmentId: string) => {
    setOpenAppointmentId((prevId) => (prevId === appointmentId ? null : appointmentId));
  };

  const renderAppointments = (appointments: any[]) => {
    if (appointments.length > 0) {
      return appointments.map((appointment, index) => (
        <UnallocatedCard
          key={index}
          appointment={appointment}
          isActionMenuOpen={openAppointmentId === appointment.id}
          onToggle={() => onMenuToggle(appointment.id)}
          onAssign={onAssign}
          anyActionMenuOpen={!!openAppointmentId}
        />
      ));
    } else {
      return <p className="text-center pt-2 fw-bold">No results found.</p>;
    }
  };

  const cardProps = {
    ...modalProps,
    className: `unallocated-appointments ${modalProps.className}`,
  };

  // Filter selected week's appointments using filters passed from search component.
  const filteredAppointments: Appointment[] = appointments.filter((appt) => {
    if (filters.client_id && appt.client.id !== filters.client_id) return false;
    if (filters.suburb && appt.client.suburb !== filters.suburb) return false;
    if (filters.date && appt.repeat_next_date !== filters.date) return false;
    if (filters.user_id && appt.user?.id !== filters.user_id) return false;

    return true;
  });

  return (
    <ModalCard {...cardProps} header="Unallocated Appointments">
      <div className="nav card-header">
        <Icon
          icon={icons.faMagnifyingGlass}
          className="search-icon me-2"
          title="Search Appointments"
          onClick={() => setSearchOpen(!isSearchOpen)}
        />
        <Icon icon={icons.faUserPen} className="me-2" title="Assign Preferred Workers" onClick={onBulkAssign} />
        <UnallocatedViewMenu />
      </div>
      <Search isOpen={isSearchOpen} appointments={appointments} weekStart={weekStart} setFilters={setFilters} />

      <div>{renderAppointments(filteredAppointments)}</div>
    </ModalCard>
  );
};

export default UnallocatedAppointments;
