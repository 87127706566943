import React, { CSSProperties } from 'react';
import { useAppSelector } from 'hooks';
import { Dayjs } from 'dayjs';

import { Worker, WorkerAvailability } from '../lib/common';
import WorkerCard from './WorkerCard';
import WorkerQualifications from './WorkerQualifications';

import { selectFocusedAppointment, selectOptions } from '../reducers/weeklyPlanner';
import TotalCard from './TotalCard';
import FocusAppointmentTotalCard from './FocusAppointmentTotalCard';

interface ComponentProps {
  worker: Worker;
  date: Dayjs;
  style?: CSSProperties;
}

const WorkerCell: React.FC<ComponentProps> = ({ worker, date, style }) => {
  const { workerQualifications, totalSimple, totalAppointments, totalPredicted, totalDistance } =
    useAppSelector(selectOptions);

  const focused = useAppSelector(selectFocusedAppointment);
  const { appointment: focusedAppointment } = focused || {};
  const { id: focusId } = focusedAppointment || {};

  const renderTotals = () => {
    const events = Object.values(worker.events).flat(1);
    if (events.length === 0) return <></>;

    if (focusId) {
      return (
        <div className="total-row">
          <FocusAppointmentTotalCard
            workerId={worker.id}
            events={events}
            availability={worker?.availability?.[date.format('dddd') as keyof WorkerAvailability] ?? {}}
          />
        </div>
      );
    }

    return (
      <div className="total-row">
        {totalSimple && <TotalCard type="simple" events={events} col={2} direction="right" />}
        {totalAppointments && <TotalCard type="appointments" events={events} col={2} direction="right" />}
        {totalPredicted && <TotalCard type="predicted" events={events} col={2} direction="right" />}
        {totalDistance && <TotalCard type="distance" events={events} col={2} direction="right" />}
      </div>
    );
  };

  const { qualifications } = worker;
  return (
    <div className="worker-cell" style={style}>
      <div className="d-flex flex-column justify-content-between w-100">
        <div>
          <div className="px-2 mb-2">
            <WorkerCard worker={worker} date={date} />
          </div>
          {workerQualifications && <WorkerQualifications qualifications={qualifications} isCondensed />}
        </div>
        <div>
          <div>{renderTotals()}</div>
        </div>
      </div>
    </div>
  );
};

export default WorkerCell;
