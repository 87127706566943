import { createSelector, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { RootState } from 'store';
import dayjs from 'dayjs';
import { LoadingState } from 'tsx/types/reducers';
import { update } from 'tsx/features/appointments/actions/appointments';

import { isUserAvailable } from '../lib/common';
import { typePrefix, simulate } from '../actions/unsaved';
import { selectWorkerFocusedRows as selectSavedAll } from './weeklyPlanner';

interface UnsavedChanges {
  [id: number]: Change;
}

interface Change {
  id: number | null;
  user_id: number;
  date: string;
  parent_repeat_id?: number;
}

interface UnsavedState {
  loading: {
    full: LoadingState;
    rows: { [id: number]: LoadingState };
  };
  error: string | null | undefined;
  rows: Array<any>;
  appointments: UnsavedChanges;
}

const initialState: UnsavedState = {
  loading: {
    full: 'idle',
    rows: {},
  },
  error: null,
  rows: [],
  appointments: {},
};

export const unsavedSlice = createSlice({
  name: `${typePrefix}-unsaved`,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(simulate.pending, (state, { meta: { arg } }) => {
      const { type, data, workers } = arg;

      const { appointments } = state;

      switch (type) {
        case 'assign': {
          const changes: UnsavedChanges = {};
          data.forEach(({ id, user_id, date, repeat, repeat_next_date, parent_repeat_id }: any) => {
            state.loading.rows[parseInt(user_id)] = 'pending';

            const changed: Change = {
              id,
              user_id,
              date,
            };

            if (repeat && repeat_next_date) {
              changed.id = null;
              changed.parent_repeat_id = parent_repeat_id;
              changed.date = repeat_next_date;
            }

            changes[id] = {
              ...appointments[id],
              ...changed,
            };
          });

          state.appointments = {
            ...appointments,
            ...changes,
          };

          break;
        }

        case 'move':
        default: {
          const { id, date, start_time, end_time } = data;
          const { origin, target } = workers;
          const changed = { id, date, start_time, end_time, ...(origin ? { user_id: target.id } : {}) };

          const appointment = {
            ...appointments[id],
            ...changed,
          };

          state.appointments = {
            ...appointments,
            [id]: appointment,
          };

          if (origin) state.loading.rows[parseInt(origin.id)] = 'pending';
          if (target) state.loading.rows[parseInt(target.id)] = 'pending';

          break;
        }
      }
    });
    builder.addCase(simulate.fulfilled, (state, action) => {
      state.loading.full = 'fulfilled';

      // Replace any existing rows with updated workers
      const workers = action.payload.data;
      const rows = state.rows;
      if (workers?.length > 0) {
        workers.forEach((worker: any) => {
          const index = rows.findIndex(({ id }) => id === worker.id);
          if (index > -1) rows[index] = worker;
          else rows.push(worker);
          state.loading.rows[worker.id] = 'fulfilled';
        });
      }

      state.rows = rows;
    });
    builder.addCase(simulate.rejected, (state) => {
      state.loading = initialState.loading;
    });
    builder.addCase(update.fulfilled, (state) => {
      state.appointments = initialState.appointments;
    });
    // Default matching for loading cases, pending when action is being called
    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading.full = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading.full = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

export const selectAll = (state: RootState) => state.weeklyPlannerUnsaved.rows;

export const selectAllChanges = (state: RootState) => state.weeklyPlannerUnsaved.appointments;

export const selectAllChangesCount = (state: RootState) =>
  Object.values(state.weeklyPlannerUnsaved.appointments).length;

export const selectLoading = (state: RootState) => state.weeklyPlannerUnsaved.loading.full;

const selectRowsLoading = (state: RootState) => state.weeklyPlannerUnsaved.loading.rows;
export const selectRowLoading = createSelector(
  [selectRowsLoading, (_: RootState, id: number) => id],
  (rows, id) => rows[id] ?? 'idle',
);

export const selectSimulatedChanges = createSelector([selectAll, selectSavedAll], (unsavedRows, savedRows) => {
  const rows = [...savedRows];
  unsavedRows.forEach((unsavedRow: any) => {
    const index = rows.findIndex(({ id }) => id === unsavedRow.id);
    if (index > -1) rows[index] = unsavedRow;
    else rows.push(unsavedRow);
  });
  return rows;
});

export const selectChangeById = createSelector(
  [selectAllChanges, (_: RootState, id: number) => id],
  (rows, id) => rows[id],
);

export const selectFilteredSimulatedAll = createSelector(
  selectSimulatedChanges,
  (state: RootState) => state.weeklyPlanner.focused,
  (state: RootState) => state.weeklyPlanner.filterOptions,
  (state: RootState) => state.weeklyPlanner.orderBy,
  (state: RootState) => state.weeklyPlanner.rows,
  (rows, focused, filterOptions, orderBy) => {
    // Return all rows if filterOptions is null
    if (!filterOptions || Object.values(filterOptions).every((value) => value === null)) {
      if (!orderBy.availability && !orderBy.distance) return rows;
    }

    const filteredRows = rows.filter((row) =>
      Object.values(filterOptions)
        .filter((ids) => Array.isArray(ids))
        .every((ids) => ids.includes(row.id)),
    );

    // order by availability / name
    if (orderBy.availability) {
      const { date, start_time, end_time } = focused?.appointment || {};
      filteredRows.sort((a, b) => {
        const aAvailable = date
          ? isUserAvailable(
              a.availability?.[dayjs(date).format('dddd') as keyof typeof a.availability] ?? {},
              start_time,
              end_time,
            )
          : false;
        const bAvailable = date
          ? isUserAvailable(
              b.availability?.[dayjs(date).format('dddd') as keyof typeof b.availability] ?? {},
              start_time,
              end_time,
            )
          : false;

        if (aAvailable && !bAvailable) return -1;
        if (!aAvailable && bAvailable) return 1;

        return a.full_name.localeCompare(b.full_name);
      });
    }

    return filteredRows;
  },
);

export default unsavedSlice.reducer;
