import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Col, Container, Row } from 'reactstrap';
import { getDayString } from 'tsx/libs/dayjs';
import ModalCard from 'tsx/components/ModalCard';
import { getMissingQualifications } from '../../actions/weeklyPlannerAppointments';
import { selectMissingQualifications } from '../../reducers/weeklyPlannerAppointments';
import WorkerQualifications from '../WorkerQualifications';
import Icon from 'tsx/components/Icon/Icon';
import { icons } from 'tsx/components/Icon';
import MissingQualificationsViewMenu from './MissingQualificationViewMenu';
import { focusWorker, selectOptions as selectWeeklyPlannerOptions } from '../../reducers/weeklyPlanner';
import Button from 'tsx/components/Button';

interface ComponentProps {
  weekStart: string;
  modalProps: any;
}

const MissingQualifications: React.FC<ComponentProps> = ({ weekStart, modalProps }) => {
  const dispatch = useAppDispatch();
  const appointments = useAppSelector(selectMissingQualifications);
  const { missingQualificationsExtendedQualifications } = useAppSelector(selectWeeklyPlannerOptions);

  const loadData = () => {
    dispatch(getMissingQualifications({ week_start: weekStart }));
  };

  useEffect(() => {
    loadData();
  }, [weekStart]);

  const renderResults = () => {
    return appointments.map(({ client, user, start_time, date, qualification_level }, index) => {
      return (
        <Row key={index} className="underline missing-qualifications-card">
          <Col>
            <div>{client?.full_name}</div>
            <div>
              {getDayString(date)} {start_time}
            </div>
          </Col>
          <Col>{user.full_name}</Col>
          <Col className="worker-qualifications">
            <WorkerQualifications
              qualifications={qualification_level.filter((item: string) => {
                return user.qualifications.includes(item) ? null : item;
              })}
              isCondensed={!missingQualificationsExtendedQualifications}
              isWrapped={!missingQualificationsExtendedQualifications}
            />
          </Col>
          <Col sm={1} className="text-center">
            <Button size="sm" className="bg-white text-dark border-0" onClick={() => dispatch(focusWorker(user.id))}>
              <Icon icon={icons.faEye} />
            </Button>
          </Col>
        </Row>
      );
    });
  };

  const missingQualificationCount = appointments.length;
  const header = `Missing Qualifications (${missingQualificationCount})`;

  return (
    <ModalCard {...modalProps} header={header}>
      <div className="nav card-header">
        <MissingQualificationsViewMenu />
      </div>
      <Container className="card-container">
        {missingQualificationCount ? (
          <>
            <Row className="fw-bold row underline flex-nowrap">
              <Col>Appointment</Col>
              <Col>Worker</Col>
              <Col>Missing Qualifications</Col>
              <Col sm={1} />
            </Row>
            {renderResults()}
          </>
        ) : (
          <Row>No Missing Qualifications found</Row>
        )}
      </Container>
    </ModalCard>
  );
};

export default MissingQualifications;
