import React, { useState } from 'react';
import { Col, Input, Row } from 'reactstrap';

import Button from 'tsx/components/Button';
import { InputProps } from 'tsx/components/FormFields';
import * as FormInput from 'tsx/components/FormFields/Inputs';

import { formatTimeString } from 'tsx/libs/dayjs';

function FormInputActualTimes({ id, value = {}, disabled, onChange }: InputProps) {
  const [override, setOverride] = useState(false);
  const { start_time_actual, end_time_actual } = value;

  const start = formatTimeString(start_time_actual);
  const end = formatTimeString(end_time_actual);
  const labelSm = 6;

  return (
    <>
      <Row className="align-items-center">
        <Col sm={labelSm} className="d-flex align-items-center">
          <Col>Start</Col>
          <Col className="d-flex align-items-center">
            {override && (
              <>
                <Input
                  id="start_time_actual"
                  type="time"
                  name="start_time_actual"
                  disabled={disabled}
                  className="ms-2 me-2"
                  value={start_time_actual ?? ''}
                  onChange={({ target: { name, value } }) => onChange && onChange(name, value)}
                />
              </>
            )}
            {!override && (
              <FormInput.ReadOnly type="readonly" id={id} name={id} value={start_time_actual ? start : 'Not entered'} />
            )}
          </Col>
        </Col>
        <Col>
          {!override && (
            <Button type="button" size="sm" color="success" onClick={() => setOverride(!override)}>
              Override
            </Button>
          )}
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col sm={labelSm} className="d-flex align-items-center">
          <Col>End</Col>
          <Col className="d-flex align-items-center">
            {override && (
              <Input
                id="end_time_actual"
                type="time"
                name="end_time_actual"
                disabled={disabled}
                className="ms-2 me-2"
                value={end_time_actual ?? ''}
                onChange={({ target: { name, value } }) => onChange && onChange(name, value)}
              />
            )}
            {!override && (
              <FormInput.ReadOnly type="readonly" id={id} name={id} value={end_time_actual ? end : 'Not entered'} />
            )}
          </Col>
        </Col>
        <Col />
      </Row>
    </>
  );
}

export default FormInputActualTimes;
