import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import addressLookup from 'tsx/features/main/reducers/addressLookup';
import app from 'tsx/features/main/reducers/app';
import appointments from 'tsx/features/appointments/reducers/appointments';
import appointmentActivities from 'tsx/features/appointments/reducers/activities';
import appointmentCancellationReasons from 'tsx/features/appointments/reducers/cancellationReasons';
import appointmentServiceTypes from 'tsx/features/appointments/reducers/serviceTypes';
import appointmentStatuses from 'tsx/features/appointments/reducers/statuses';
import appointmentRepeatFrequencies from 'tsx/features/appointments/reducers/repeatFrequencies';
import careQualificationLevels from 'tsx/features/appointments/reducers/careQualificationLevels';
import clients from 'tsx/features/main/reducers/clients';
import clientPackages from 'tsx/features/main/reducers/clientPackages';
import clientPackageSchedules from 'tsx/features/main/reducers/clientPackageSchedules';
import clientReferrers from 'tsx/features/main/reducers/clientReferrers';
import equipments from 'tsx/features/main/reducers/equipments';
import locations from 'tsx/features/appointments/reducers/locations';
import medications from 'tsx/features/main/reducers/medications';
import taskClassifications from 'tsx/features/main/reducers/taskClassifications';
import taskTypes from 'tsx/features/main/reducers/taskTypes';
import users from 'tsx/features/main/reducers/users';
import leaveCodes from 'tsx/features/main/reducers/leaveCodes';
import paymentTypes from 'tsx/features/main/reducers/paymentTypes';
import states from 'tsx/features/main/reducers/states';
import leaveRequests from 'tsx/features/main/reducers/leaveRequests';
import login from 'tsx/features/main/reducers/login';
import userAppointments from 'tsx/features/appointments/reducers/userAppointments';
import userDepartments from 'tsx/features/care-workers/reducers/userDepartments';
import userLevels from 'tsx/features/care-workers/reducers/userLevels';
import userPayGroups from 'tsx/features/care-workers/reducers/userPayGroups';
import userPayLevels from 'tsx/features/care-workers/reducers/userPayLevels';
import ndisSupportItems from 'tsx/features/main/reducers/ndisSupportItems';
import userAllowances from 'tsx/features/main/reducers/userAllowances';
import weeklyPlanner from 'tsx/features/weekly-planner/reducers/weeklyPlanner';
import weeklyPlannerUnsaved from 'tsx/features/weekly-planner/reducers/unsaved';
import weeklyPlannerAppointments from 'tsx/features/weekly-planner/reducers/weeklyPlannerAppointments';

export const store = configureStore({
  reducer: {
    addressLookup,
    app,
    appointments,
    appointmentCancellationReasons,
    appointmentServiceTypes,
    appointmentRepeatFrequencies,
    appointmentActivities,
    appointmentStatuses,
    clients,
    clientPackages,
    clientPackageSchedules,
    clientReferrers,
    careQualificationLevels,
    equipments,
    leaveCodes,
    leaveRequests,
    locations,
    login,
    medications,
    ndisSupportItems,
    paymentTypes,
    states,
    taskTypes,
    taskClassifications,
    users,
    userAppointments,
    userDepartments,
    userLevels,
    userPayGroups,
    userPayLevels,
    userAllowances,
    weeklyPlanner,
    weeklyPlannerAppointments,
    weeklyPlannerUnsaved,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
