import React, { useEffect, useState } from 'react';
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Button } from 'reactstrap';
import dayjs, { Dayjs } from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import updateLocale from 'dayjs/plugin/updateLocale';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { DateCalendar } from '@mui/x-date-pickers';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import classNames from 'classnames';

import { dateDisplayFormat, isInSameWeek } from 'tsx/libs/dayjs';
import { Icon, icons } from './Icon';

dayjs.extend(weekday);
dayjs.extend(isBetweenPlugin);
dayjs.extend(updateLocale);

interface Props {
  weekStart?: number;
  onChange?: (value: Dayjs) => void;
  quickIcons?: boolean;
}

const WeekPicker: React.FC<Props> = ({ weekStart = 1, onChange, quickIcons = true }: Props) => {
  useEffect(() => {
    // Set starting week day on mount
    dayjs.updateLocale('en', {
      weekStart,
    });
  }, []);

  // Must maintain value in local state to ensure locale is correct.
  // Any outside interaction (onChange, etc) must be called in tandem, not substituted.
  const [hoveredDay, setHoveredDay] = useState<Dayjs | null>(null);
  const [value, setValue] = useState(dayjs());

  const setChange = (value: Dayjs) => {
    setValue(value);
    onChange && onChange(value);
  };

  // Custom day component, sets classname to any day being hovered over or within the week selection.
  const Day = ({ day, selectedDay, hoveredDay, ...other }: any) => {
    const className = classNames('day', {
      hovered: isInSameWeek(day, hoveredDay),
      selected: isInSameWeek(day, selectedDay),
    });

    return <PickersDay {...other} className={className} day={day} sx={{ px: 2.5 }} disableMargin selected={false} />;
  };

  const start = value.weekday(0).format(dateDisplayFormat);
  const end = value.weekday(6).format(dateDisplayFormat);

  return (
    <div className="d-flex float-right">
      <UncontrolledDropdown className="ms-3 d-flex flex-row align-items-center me-1">
        {quickIcons && (
          <Button
            className="date-chevron d-flex align-items-center justify-content-center"
            onClick={() => setChange(dayjs(start).subtract(7, 'days'))}
          >
            <Icon icon={icons.faChevronLeft} />
          </Button>
        )}
        <DropdownToggle size="sm" caret color="none" className="selectable mx-1">
          {start} - {end}
        </DropdownToggle>
        {quickIcons && (
          <Button
            className="date-chevron d-flex align-items-center justify-content-center"
            onClick={() => setChange(dayjs(start).add(7, 'days'))}
          >
            <Icon icon={icons.faChevronRight} />
          </Button>
        )}
        <DropdownMenu>
          <DateCalendar
            value={value}
            onChange={setChange}
            className="week-picker"
            showDaysOutsideCurrentMonth
            slots={{ day: Day }}
            slotProps={{
              day: (state) => ({
                selectedDay: value,
                hoveredDay,
                onPointerEnter: () => setHoveredDay(state.day),
                onPointerLeave: () => setHoveredDay(null),
              }),
            }}
          />
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default WeekPicker;
