import React, { CSSProperties, useEffect, useState } from 'react';
import { useAppSelector } from 'hooks';
import { useDraggable } from '@dnd-kit/core';
import classNames from 'classnames';
import tinycolor from 'tinycolor2';

import { Appointment, Event, formatDistanceDisplayString, maxDistance } from '../lib/common';
import { getPredictedTime } from '../lib/simulate';

import { PIP_INTERVALS } from './DailyView/DailyView';

import { selectServiceTypeColourById } from 'tsx/features/appointments/reducers/serviceTypes';
import { selectFocusedAppointment, selectOpenAppointmentDetailsId } from '../reducers/weeklyPlanner';
import AppointmentPopover from './AppointmentPopover';
import { selectChangeById } from '../reducers/unsaved';
import { timeValueFormat } from 'tsx/libs/dayjs';

interface ComponentProps {
  appointment: Appointment;
  className?: string;
  style?: CSSProperties;
  containerId?: string;
  containerWidth?: number;
  maxSegments?: number;
  displayOnTransform?: boolean;
  events: Event[];
}

const AppointmentCard: React.FC<ComponentProps> = ({
  containerId,
  appointment,
  className,
  style,
  containerWidth,
  maxSegments,
  displayOnTransform = false,
  events = [],
}) => {
  const {
    id,
    client,
    start_time,
    end_time,
    duration,
    status,
    flexibility,
    qualification_level,
    date,
    service_type,
    parent_repeat_id,
  } = appointment;
  const { id: client_id, full_name, referrer_name } = client;
  const [isPopOpen, setPopOpen] = useState(false);
  const poppedId = useAppSelector(selectOpenAppointmentDetailsId);
  const hasChanged = useAppSelector((state) => selectChangeById(state, parseInt(id)));
  const focused = useAppSelector(selectFocusedAppointment);
  const { appointment: focusedAppointment, isAvailabilityView } = focused || {};
  const { id: focusId, client: focusAppointmentClient } = focusedAppointment || {};
  const contextTarget = `appointment-card-${id}`;
  const appointmentPopoverInfo = {
    name: full_name,
    referrer: referrer_name,
    status: status.name,
    date,
    time: {
      start: start_time,
      end: end_time,
      duration,
    },
    flexibility,
    qualification_level,
    service_type: service_type.name,
  };

  useEffect(() => {
    const shouldPop = Number(id) === poppedId;
    if (shouldPop && !isPopOpen) setPopOpen(true);
    else if (!shouldPop && isPopOpen) setPopOpen(false);
  }, [poppedId]);

  // Set optional variables, only set if a containerId exists
  let attributes, listeners, setNodeRef, transform;
  if (containerId) {
    // Only establish draggable if a containerId exists
    ({ attributes, listeners, setNodeRef, transform } = useDraggable({
      id,
      data: { containerId, containerStyle: style, appointment },
    }));
  }

  const { x, y } = transform ?? {};
  const serviceTypeColour = useAppSelector((state) => selectServiceTypeColourById(state, service_type?.id));

  const backgroundColor = serviceTypeColour ? serviceTypeColour : 'white';

  let appointmentCardStyle: CSSProperties = {
    transform: transform ? `translate3d(${x}px, ${!displayOnTransform ? y : '0'}px, 0)` : undefined,
    backgroundColor,
    color: tinycolor(backgroundColor).isDark() ? 'white' : undefined,
    ...style,
  };

  const statusStripStyle: CSSProperties = {
    backgroundColor: status?.colour ?? undefined,
  };

  // If moving on the x scale, show the transformed start and end times.
  // CAREFUL, offsetMinutes is based on the current container size, x position and movement made on drag.
  let displayStart = start_time;
  let displayEnd = end_time;
  if (transform && displayOnTransform) {
    displayStart = getPredictedTime(containerWidth ?? 1, x ?? 0, maxSegments ?? 1, start_time, PIP_INTERVALS).format(
      timeValueFormat,
    );
    displayEnd = getPredictedTime(containerWidth ?? 1, x ?? 0, maxSegments ?? 1, end_time, PIP_INTERVALS).format(
      timeValueFormat,
    );
  }

  // distance display for focusAppointment view (distance focus)
  const maxDist = formatDistanceDisplayString(maxDistance(events));
  const displayDistance = (focusId && !isAvailabilityView) ?? false;
  const distanceInfo = `${date}\n${start_time} - ${end_time}\n${full_name}\n${maxDist}s from ${focusAppointmentClient?.full_name}`;
  const displayTitle = displayDistance ? maxDist : full_name;
  if (displayDistance) {
    appointmentCardStyle = {
      ...appointmentCardStyle,
      backgroundImage: `linear-gradient(to bottom, red 40%, ${status?.colour ?? undefined} 40%)`,
    };
  }

  const extraProps = {
    ref: setNodeRef,
    ...attributes,
    ...listeners,
  };

  const cardClassName = classNames(
    'appointment-card tooltip-container',
    {
      [`${className}`]: className !== undefined,
    },
    hasChanged ? 'changed' : undefined,
  );

  return (
    <>
      <div
        className={cardClassName}
        id={contextTarget}
        data-id={id}
        data-client-id={client_id}
        data-parent-repeat-id={parent_repeat_id ?? undefined}
        data-tooltip={!displayDistance ? `${displayTitle} (${start_time} - ${end_time})` : distanceInfo}
        style={appointmentCardStyle}
        {...extraProps}
        onClick={(clickEvent) => clickEvent.stopPropagation()}
        onContextMenu={(contextClick) => {
          contextClick.preventDefault();
        }}
      >
        {!displayDistance && <div className="status-strip" style={statusStripStyle} />}
        <div className="appointment-info">
          <div>
            <strong>{displayTitle}</strong>
          </div>
          <div>
            {displayStart} - {displayEnd}
          </div>
        </div>
      </div>

      <AppointmentPopover isOpen={isPopOpen} target={contextTarget} appointmentPopoverInfo={appointmentPopoverInfo} />
    </>
  );
};

export default AppointmentCard;
