import dayjs from 'dayjs';
import React from 'react';
import { Col, PopoverBody, PopoverHeader, Row, UncontrolledPopover } from 'reactstrap';
import { weeklyPlannerDayDisplayFormat } from 'tsx/libs/dayjs';
import WorkerQualifications from './WorkerQualifications';

interface AppointmentPopoverInfo {
  name: string;
  referrer: string;
  status: string;
  date: string;
  time: {
    start: string;
    end: string;
    duration: number;
  };
  flexibility: boolean;
  qualification_level: string[];
  service_type: string;
}

interface ComponentProps {
  isOpen: boolean;
  target: string;
  toggle?: () => void;
  appointmentPopoverInfo: AppointmentPopoverInfo;
}

const AppointmentPopover: React.FC<ComponentProps> = ({ isOpen = false, target, toggle, appointmentPopoverInfo }) => {
  const { name, referrer, status, date, time, flexibility, qualification_level, service_type } = appointmentPopoverInfo;

  return (
    <UncontrolledPopover isOpen={isOpen} toggle={toggle} target={target} className="appointment-popover">
      <PopoverHeader className="bg-success text-white">Appointment Details</PopoverHeader>
      <PopoverBody className="pop-body">
        <Row className="title mb-2">{name} </Row>
        <Row>
          <Col lg={3}>Status:</Col>
          <Col className="text-end">{status}</Col>
        </Row>
        <Row>
          <Col lg={3}>Referrer:</Col>
          <Col className="text-end">{referrer || '-'}</Col>
        </Row>
        <Row>
          <Col lg={3}>Service:</Col>
          <Col className="text-end">{service_type}</Col>
        </Row>
        <Row>
          <Col lg={3}>Day:</Col>
          <Col className="text-end">{dayjs(date).format(weeklyPlannerDayDisplayFormat)}</Col>
        </Row>
        <Row>
          <Col lg={3}>Time:</Col>
          <Col className="text-end">
            {time.start} - {time.end} ({time.duration} mins)
          </Col>
        </Row>
        <Col className={`mb-3 text-end ${flexibility ? 'text-success' : 'text-danger'}`}>
          {flexibility ? '' : 'Not '}Flexible With Times
        </Col>
        <Row className="mb-3">
          {!qualification_level || qualification_level.length === 0 || qualification_level[0] === '0' ? (
            <Row>No Qualifications Required.</Row>
          ) : (
            <Row>
              Qualifications Required:
              <WorkerQualifications isCondensed qualifications={qualification_level} />
            </Row>
          )}
        </Row>
        <Row>
          {dayjs().isAfter(date) && (
            <div className="text-danger">
              <p className="fw-bold mb-0">This appointment date has passed.</p>
              <p>Do not remove unless there has been a clerical / data entry.</p>
            </div>
          )}
        </Row>
      </PopoverBody>
    </UncontrolledPopover>
  );
};

export default AppointmentPopover;
