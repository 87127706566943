import { SearchProps } from 'tsx/components/FormFields/Search';
import { selectLookupAppointmentServiceTypes } from 'tsx/features/appointments/reducers/serviceTypes';
import { selectAppointmentStatuses } from 'tsx/features/appointments/reducers/statuses';
import { selectClientReferrers } from 'tsx/features/main/reducers/clientReferrers';
import { selectClientPackages } from 'tsx/features/main/reducers/clientPackages';

const fields: SearchProps = {
  text_search: {
    persist: true,
    type: 'text-like',
    field: ['client.full_name', 'user.full_name', 'reference_number', 'client.referrer_name', 'package_fixed.name'],
    placeholder: 'Basic search',
  },
  client_name: {
    type: 'text-like',
    field: 'client.full_name',
    placeholder: 'Search client',
    caption: 'Client',
  },
  reference_number: {
    type: 'text-like',
    field: 'reference_number',
    placeholder: 'Search reference',
    caption: 'Reference',
  },
  worker_name: {
    type: 'text-like',
    field: 'user.full_name',
    placeholder: 'Search care worker',
    caption: 'Care Worker',
  },
  date: {
    defaultShow: true,
    type: 'date-range',
    field: 'date',
    caption: 'Date',
  },
  status: {
    type: 'multi-lookup',
    field: 'status_id',
    caption: 'Status',
    placeholder: 'All',
    selectorOptions: selectAppointmentStatuses,
  },
  start_time: {
    type: 'time-range',
    field: 'start_time',
    caption: 'Start Time',
  },
  end_time: {
    type: 'time-range',
    field: 'end_time',
    caption: 'End Time',
  },
  referrer: {
    type: 'multi-lookup',
    field: 'client.referrer_name',
    caption: 'Referrer',
    placeholder: 'All',
    selectorOptions: selectClientReferrers,
  },
  client_package: {
    type: 'multi-lookup',
    field: 'package_fixed_id',
    caption: 'Package',
    placeholder: 'All',
    selectorOptions: selectClientPackages,
  },
  service_type: {
    type: 'multi-lookup',
    field: 'service_type_id',
    caption: 'Service Type',
    placeholder: 'All',
    selectorOptions: selectLookupAppointmentServiceTypes,
  },
};

export default fields;
