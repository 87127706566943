import React from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from 'tsx/components/Button';
import ContainerHeader from 'tsx/components/ContainerHeader';
import ContainerBanner from 'tsx/components/ContainerBanner';
import ErrorResponse from 'tsx/components/ErrorResponse';
import { Icon, icons } from 'tsx/components/Icon';
import { AppointmentStatuses } from 'tsx/constants/maps';
import {
  clearMessage,
  copyAppointment,
  selectAppointmentMessage,
  selectCurrentAppointment,
  selectErrorResponse,
} from 'tsx/features/appointments/reducers/appointments';
import { AuthTags } from 'tsx/constants/authTags';
import { selectUserTags } from 'tsx/features/main/reducers/login';

const AppointmentBanner: React.FC<any> = ({
  isNew = false,
  isRepeat = false,
  isTransport = false,
  isCancellation = false,
  isDeclined = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const appointment = useAppSelector(selectCurrentAppointment);
  const errorResponse = useAppSelector(selectErrorResponse);
  const message = useAppSelector(selectAppointmentMessage);
  const tags = useAppSelector(selectUserTags);

  const messages = [];

  const REDIRECT_URL = process.env.REACT_APP_TPONE_URL;
  const parentExists = appointment?.parent_repeat_id != null && appointment?.parent_repeat_id > 0;
  const parent = appointment?.parent_repeat;

  if (appointment?.status_id === AppointmentStatuses.STARTED) messages.push('Appointment has already started');
  if (appointment?.declined) messages.push('Appointment was declined');

  const authorised = tags.includes(AuthTags.APPOINTMENTS.UPDATE);

  const onBack = () => {
    if (isRepeat) navigate('/appointments/repeats');
    else navigate('/appointments');
  };

  const getExportedMessages = () => {
    const messages = [];
    if (appointment?.exported_payroll) messages.push('Exported to Payroll');
    if (appointment?.exported_invoices) messages.push('Exported to Invoice');
    return messages.map((message) => `${message} - Only accounts users can alter this appointment`);
  };

  const copy = (link: string) => {
    // Copy appointment into reducer
    dispatch(copyAppointment(appointment));

    // Navigate to link
    navigate(link);
  };

  const exportMessages = getExportedMessages();

  return (
    <>
      <ContainerHeader className={`${isRepeat ? 'warning text-white' : 'info'}`}>
        {isRepeat && <span className="me-1">Repeat</span>}
        {isTransport && <span className="me-1">Transport</span>}
        <span>Appointment Details - {isNew ? 'New' : `Ref #${appointment?.reference_number}`}</span>
      </ContainerHeader>
      <div className="d-flex justify-content-between bg-light p-2 mt-2 mb-2">
        <div>
          {isDeclined && (
            <Link to={`${REDIRECT_URL}/redirect-url.asp?redirectArg=declined`}>
              <Button size="sm" color="danger" className="me-2 nav-element">
                <Icon className="me-2" icon={icons.faArrowLeft} />
                Back to Declined Appointments
              </Button>
            </Link>
          )}
          <Button size="sm" color="success" className="me-2 nav-element" onClick={() => onBack()}>
            <Icon className="me-2" icon={icons.faArrowLeft} />
            Back to Appointments
          </Button>
          {!isNew && (
            <Link to={`${REDIRECT_URL}/client-details.asp?eid=${appointment?.client_id}`}>
              <Button
                size="sm"
                color="success"
                className="me-2 nav-element"
                disabled={isNaN(parseInt(appointment?.client_id))}
              >
                <Icon className="me-2" icon={icons.faArrowLeft} />
                Back to Client Details
              </Button>
            </Link>
          )}
        </div>
        <div>
          {authorised && (
            <>
              <span>Create Similar:</span>
              {!isRepeat && (
                <>
                  <Button
                    size="sm"
                    color="success"
                    className="ms-2 me-2"
                    disabled={isNew}
                    onClick={() => copy('/appointments/new')}
                  >
                    <Icon className="me-2" icon={icons.faPlus} />
                    Appointment
                  </Button>
                  <span>/</span>{' '}
                </>
              )}
              <Button
                size="sm"
                color="warning"
                className="ms-2 me-2"
                disabled={isNew}
                onClick={() => copy('/appointments/repeats/new')}
              >
                <Icon className="me-2" icon={icons.faPlus} />
                Repeat
              </Button>
            </>
          )}
          <Button
            authTag={AuthTags.APPOINTMENTS.CANCEL}
            size="sm"
            color="danger"
            className="me-2"
            disabled={isNew}
            onClick={() => navigate(`/appointments/${id}/cancel`)}
          >
            <Icon className="me-2" icon={icons.faBan} />
            Cancel Appointment
          </Button>
        </div>
      </div>
      {exportMessages.length > 0 && (
        <ContainerBanner className="m-2" color="info" icon={icons.faLock} iconSize="1x" messages={exportMessages} />
      )}
      {message && (
        <ContainerBanner
          className="m-2 lead"
          color="success"
          textColor="white"
          icon={icons.faInfoCircle}
          iconSize="1x"
          messages={[message]}
          onClear={() => dispatch(clearMessage())}
        />
      )}
      {messages.length > 0 && (
        <ContainerBanner className="m-2" color="info" icon={icons.faInfoCircle} iconSize="2x" messages={messages} />
      )}
      {isCancellation && (
        <ContainerBanner
          className="mt-3 mb-3"
          color="danger"
          textColor="white"
          icon={icons.faTriangleExclamation}
          iconSize="2x"
          messages={['Are you sure you wish to cancel this appointment?']}
        />
      )}
      <ErrorResponse message={errorResponse} />
      {parentExists && (
        <div
          className="m-2 p-2 border-2 border-top border-bottom border-warning selectable"
          onClick={() => navigate(`/appointments/${parent?.id}`)}
        >
          {`This appointment is part of a repeat series. Click to view repeat appointment #${parent?.reference_number}`}
        </div>
      )}
    </>
  );
};

export default AppointmentBanner;
