import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Col, Container, Row } from 'reactstrap';
import { getDayString } from 'tsx/libs/dayjs';
import Button from 'tsx/components/Button';
import { Icon, icons } from 'tsx/components/Icon';
import ModalCard from 'tsx/components/ModalCard';

import { getConflicts } from '../../actions/weeklyPlannerAppointments';
import { selectConflicts } from '../../reducers/weeklyPlannerAppointments';
import { focusWorker } from '../../reducers/weeklyPlanner';

interface ComponentProps {
  weekStart: string;
  modalProps: any;
}

const Conflicts: React.FC<ComponentProps> = ({ weekStart, modalProps }) => {
  const dispatch = useAppDispatch();
  const appointments = useAppSelector(selectConflicts);

  const loadData = () => {
    dispatch(getConflicts({ week_start: weekStart }));
  };

  useEffect(() => {
    loadData();
  }, [weekStart]);

  const renderResults = () => {
    return appointments.map(({ client, user, start_time, date, conflictType }, index) => {
      return (
        <Row key={index} className="underline">
          <Col>
            <div>{client.full_name}</div>
            <div>
              {getDayString(date)} {start_time}
            </div>
          </Col>
          <Col>{user.full_name}</Col>
          <Col>{conflictType}</Col>
          <Col sm={1} className="text-center">
            <Button size="sm" className="bg-white text-dark border-0" onClick={() => dispatch(focusWorker(user.id))}>
              <Icon icon={icons.faEye} />
            </Button>
          </Col>
        </Row>
      );
    });
  };

  const conflictCount = appointments.length;
  const header = `Conflicts (${conflictCount})`;

  return (
    <ModalCard {...modalProps} header={header}>
      <Container className="card-container">
        {conflictCount ? (
          <>
            <Row className="fw-bold row underline flex-nowrap">
              <Col>Appointment</Col>
              <Col>Worker</Col>
              <Col>Conflict</Col>
              <Col sm={1} />
            </Row>
            {renderResults()}
          </>
        ) : (
          <Row>No conflicts found</Row>
        )}
      </Container>
    </ModalCard>
  );
};

export default Conflicts;
