import React, { useEffect, useState, CSSProperties } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Button, Dropdown, DropdownItem, DropdownMenu, Label } from 'reactstrap';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { weeklyPlannerDayDisplayFormat } from 'tsx/libs/dayjs';
import { Appointment } from '../lib/common';

import { Icon, icons } from 'tsx/components/Icon';
import WorkerQualifications from './WorkerQualifications';

import {
  focusAppointment,
  selectFocusedAppointment,
  selectOptions as selectWeeklyPlannerOptions,
} from '../reducers/weeklyPlanner';
import { Select } from 'tsx/components/FormFields/Inputs';

interface ComponentProps {
  appointment: Appointment;
  canAction?: boolean;
  showStatus?: boolean;
  isActionMenuOpen?: boolean;
  anyActionMenuOpen?: boolean;
  onToggle?: () => void;
  onAssign?: (target: Appointment) => void;
}

const UnallocatedCard: React.FC<ComponentProps> = ({
  appointment,
  canAction = true,
  showStatus = false,
  isActionMenuOpen,
  onToggle,
  onAssign,
  anyActionMenuOpen,
}) => {
  const dispatch = useAppDispatch();
  const { unallocatedDuration, unallocatedQualifications, unallocatedServiceType, unallocatedSuburb } =
    useAppSelector(selectWeeklyPlannerOptions);

  const focused = useAppSelector(selectFocusedAppointment);
  const { appointment: focusedAppointment } = focused || {};
  const { id: focusId, is_bidding: focusBidding, bidding_user_ids: focusBiddingIds } = focusedAppointment || {};

  const toggleFocusView = (isAvailabilityView: boolean, isBiddingView = false) => {
    const displayDate = repeat && repeat_next_date ? repeat_next_date : date;
    dispatch(
      focusAppointment({
        appointment: {
          ...appointment,
          date: displayDate,
        },
        isAvailabilityView,
        isBiddingView,
      }),
    );
  };

  const {
    id,
    client,
    date,
    user_id,
    repeat,
    repeat_next_date,
    start_time,
    end_time,
    duration,
    service_type,
    status,
    qualification_level,
    assignable_users = [],
    is_bidding,
    bidding_user_ids,
  } = appointment;
  const { full_name, suburb } = client;

  const [allocatedUserId, setAllocatedUserId] = useState<number | ''>('');
  const [localBidding, setLocalBidding] = useState(is_bidding);
  const [localBiddingUsers, setLocalBiddingUsers] = useState(bidding_user_ids || []);

  useEffect(() => {
    if (focusId && focusId === id) {
      setLocalBidding(focusBidding ?? false);
      setLocalBiddingUsers(focusBiddingIds || []);
    }
  }, [focusBidding, focusBiddingIds, appointment.id]);

  // sort not available last, then not qualified. Alphabetical within those groups
  const sortedUsers = [...assignable_users].sort((a, b) => {
    if (a.is_available !== b.is_available) {
      return a.is_available ? -1 : 1;
    }

    if (a.is_qualified !== b.is_qualified) {
      return a.is_qualified ? -1 : 1;
    }
    return a.name.localeCompare(b.name);
  });

  const selectOptions = sortedUsers.map(({ id, name, is_qualified, is_available }) => ({
    id,
    name: `${name}${!is_qualified ? ' (NQ)' : ''}${!is_available ? ' (NA)' : ''}`,
    isQualified: is_qualified,
    isAvailable: is_available,
  }));

  const cardClass = classNames('unallocated-card', {
    'greyed-out': (anyActionMenuOpen && !isActionMenuOpen) || (focusId && focusId !== id),
  });

  const appointmentClass = classNames('appointment-content', {
    'hide-address': !unallocatedSuburb,
  });

  const statusStyle: CSSProperties = {
    backgroundColor: status?.colour ?? undefined,
  };
  const biddingStyle: CSSProperties = {
    color: localBidding && localBiddingUsers.length === 0 ? 'red' : 'black',
  };

  const showActions = canAction && isActionMenuOpen !== undefined;
  //TODO: Clean up styling
  const getOptionStyle = (option: any) => {
    let backgroundColor = 'white';
    if (!option.isAvailable) {
      backgroundColor = 'lightpink';
    } else if (!option.isQualified) {
      backgroundColor = 'lightyellow';
    }

    return {
      backgroundColor,
      color: 'black',
    };
  };

  const renderPreference = () => {
    let displayPreference = 'Appt has no worker preference';

    const classes = [];
    if (repeat && user_id) {
      const user = selectOptions.find(({ id }) => id === Number(user_id));
      if (user) {
        const { name, isAvailable, isQualified } = user;
        displayPreference = `Worker preference: ${name}`;

        if (!isAvailable) classes.push('not-available');
        if (!isQualified) classes.push('not-qualified');
      }
    }

    const className = classNames('preference', classes);
    return <div className={className}>{displayPreference}</div>;
  };

  const handleSelectChange = (option: any) => {
    setAllocatedUserId(option?.value);
  };

  const handleAllocateUser = () => {
    if (allocatedUserId === '') return;
    onAssign &&
      onAssign({
        ...appointment,
        user_id: allocatedUserId,
      });

    onToggle && onToggle();
  };

  const displayDate = repeat && repeat_next_date ? repeat_next_date : date;

  return (
    <div className={cardClass}>
      {showStatus && <div className="status-strip" style={statusStyle} />}
      <div className="content">
        <div className={appointmentClass}>
          <div className="client">
            <div>{full_name}</div>
            {unallocatedSuburb && <div>{suburb}</div>}
            {unallocatedServiceType && <div className="service">{service_type?.name || ''}</div>}
          </div>
          <div className="date-time">
            <div>{dayjs(displayDate).format(weeklyPlannerDayDisplayFormat)}</div>
            <div className="start-end">
              {start_time} - {end_time}
            </div>
            {unallocatedDuration && <div className="duration">{duration} mins</div>}
          </div>
        </div>

        {unallocatedQualifications && (
          <div className="qualifications">
            <WorkerQualifications qualifications={qualification_level} />
          </div>
        )}
        <div className="extras">
          {renderPreference()}
          <div className="d-flex">
            {localBidding && (
              <div className="fw-bold me-2" style={biddingStyle}>
                {`Bidding (${localBiddingUsers.length} CW)`}
              </div>
            )}
            {repeat && (
              <div>
                <Icon icon={icons.faRepeat} title="Repeat Appointment" />
              </div>
            )}
          </div>
        </div>
      </div>

      {showActions && (
        <>
          <div className="action-toggle selectable" onClick={onToggle}>
            <Icon icon={isActionMenuOpen ? icons.faChevronLeft : icons.faChevronRight} />
          </div>

          <div className="action-menu-wrapper">
            <Dropdown isOpen={isActionMenuOpen} toggle={onToggle} direction="end">
              <DropdownMenu className="action-menu">
                <DropdownItem header>Allocate</DropdownItem>
                <div className="d-flex align-items-center">
                  <div className="action-menu-selector">
                    <Select
                      id={`allocate-user-${appointment.id}`}
                      name={`allocate-user-${appointment.id}`}
                      type="select"
                      placeholder="Worker"
                      value={allocatedUserId}
                      options={selectOptions}
                      className="p-2"
                      getOptionStyle={getOptionStyle}
                      onChange={(_id, option) => handleSelectChange(option)}
                    />
                  </div>
                  <div className="action-menu-button">
                    <Button size="sm" color="success" className="ps-4 pe-4 me-2" onClick={handleAllocateUser}>
                      Go
                    </Button>
                  </div>
                </div>
                <DropdownItem onClick={() => toggleFocusView(true)}>
                  <Label className="ms-2 mb-0">By Availability</Label>
                </DropdownItem>
                <DropdownItem onClick={() => toggleFocusView(false)}>
                  <Label className="ms-2 mb-0">By Distance (KMs)</Label>
                </DropdownItem>
                <DropdownItem header>Other</DropdownItem>
                <DropdownItem onClick={() => toggleFocusView(true, true)}>
                  <Label className="ms-2 mb-0">Bidding</Label>
                </DropdownItem>
                <DropdownItem disabled>
                  <Label className="ms-2 mb-0">Edit Appointment</Label>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </>
      )}
    </div>
  );
};

export default UnallocatedCard;
